import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svgs/dark-logo.svg";
import { useTranslation } from "react-i18next";
import { switchLang } from "../../assets/helpers/lang.js";
import "./Header.css";

export default function Header() {
  const [menuToggle, setMenuToggle] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    switchLang(lng);
  };
  return (
    <>
      <div className="header small-header" data-aos="fade-down">
        <div className="container">
          <ul>
            <li>
              <a rel="noreferrer" href="mailto:Info@edsacad.com" target="_blank" ><i className="fa-solid fa-envelope"></i> Info@edsacad.com </a>
              <a rel="noreferrer" href="tel:+966138313000" target="_blank"><i className="fa-solid fa-phone"></i> +966 138 313 000 </a>
            </li>
            <li>
              <a rel="noreferrer" target="_blank" href="https://www.facebook.com"><i className="fa-brands fa-facebook-f"></i></a>
              <a rel="noreferrer" target="_blank" href="https://www.linkedin.com"><i className="fa-brands fa-linkedin-in"></i></a>
              <a rel="noreferrer" target="_blank" href="https://www.x.com"><i className="fa-brands fa-x-twitter"></i></a>
            </li>
          </ul>
        </div>
      </div >
      <div className="header" data-aos="fade-down">
        <div className="container">
          <ul>
            <li>
              <Link className="logo" onClick={(e) => { e.preventDefault(); window.location.href = "/" }} to={`/`}>
                <Logo />
              </Link>
            </li>
            <li>
              <ul className={`nav-links ${menuToggle && "show"}`}>
                <li onClick={() => { setMenuToggle(!menuToggle) }}>
                  <Link style={{ color: "#000" }} onClick={(e) => { e.preventDefault(); window.location.href = "/#home" }} to={`/`}>{t("header.home")}</Link>
                </li>
                <li onClick={() => { setMenuToggle(!menuToggle) }}>
                  <Link style={{ color: "#000" }} onClick={(e) => { e.preventDefault(); window.location.href = "/#services" }} to={`/#services`}>{t("header.services")}</Link>
                </li>
                <li onClick={() => { setMenuToggle(!menuToggle) }}>
                  <Link style={{ color: "#000" }} onClick={(e) => { e.preventDefault(); window.location.href = "/#about" }} to={`/#about`}>{t("header.about")}</Link>
                </li>
                <li onClick={() => { setMenuToggle(!menuToggle) }}>
                  <Link style={{ color: "#000" }} onClick={(e) => { e.preventDefault(); window.location.href = "/#contact" }} to={`/#contact`}>{t("header.contact")}</Link>
                </li>
                {/* <li onClick={() => { setMenuToggle(!menuToggle) }}>
                  <Link style={{ color: "#000" }} onClick={(e) => { e.preventDefault(); window.location.href = "/portfolio" }} to={`/portfolio`}>{t("header.portfolio")}</Link>
                </li> */}
              </ul>
            </li>
            <li>
              <button className="menu-btn" onClick={() => { setMenuToggle(!menuToggle) }}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              {t("lang") === "ar" ? (
                <button className="lang-btn" onClick={() => changeLanguage("en")}> EN <i className="fa-solid fa-globe"></i> </button>
              ) : (
                <button className="lang-btn" onClick={() => changeLanguage("ar")}> <i className="fa-solid fa-globe"></i> AR </button>
              )}
            </li>
          </ul>
        </div >
      </div >
    </>
  );
}
