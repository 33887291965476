import React, { useEffect, useState } from 'react';
import { checkFixLang } from "./assets/helpers/lang";
import i18n from "./assets/helpers/i18n.js";
import Header from './components/Header/Header.jsx';
import logoAnimation from "./assets/logo.mp4";
import Footer from './components/Footer/Footer.jsx';
import AnimatedCursor from 'react-animated-cursor';
import AOS from 'aos';
import RouterHandler from './router/RouterHandler.jsx';
import 'aos/dist/aos.css';

function App() {
  const [lang] = useState(localStorage.getItem('i18nextLng') || 'ar');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkFixLang(lang);
  }, [lang]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    });

    setTimeout(() => {
      setLoading(false);
    }, 10400);

    return () => {
      AOS.refresh();
    }
  }, []);

  return loading ? <video autoPlay muted className='animated-logo'><source src={logoAnimation} type="video/mp4" /></video> :
    <div className="App">
      <div className="mouse-cursor">
        <AnimatedCursor
          innerSize={18}
          outerSize={18}
          color='125, 125, 125'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
        />
      </div>
      <Header />
      <RouterHandler />
      <Footer />
    </div>
}

export default App;
