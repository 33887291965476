import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: { en: { translations: require("./translation/en.json"), }, ar: { translations: require("./translation/ar.json"), }, },
    fallbackLng: "ar",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: { escapeValue: false, },
});

export default i18n;
