import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import "./ContactForm.css";

export default function ContactForm() {
  const { t } = useTranslation();
  const form = useRef();
  const serviceID = 'default_service';
  const templateID = 'contact_form';
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (name === "" || email === "" || phone === "" || message === "") {
      alert(t("contact.fill"));
    } else {
      setSubmitted(true);
      emailjs.sendForm(serviceID, templateID, form?.current, 'qaXd5kKGaXtkrM62v').then(
        (result) => {
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setTimeout(() => {
            setSubmitted(false);
          }, 5000);
        },
        (error) => {
          console.log(error);
          setSubmitted(false);
        }
      );
    }
  };

  return (
    <div className="container" data-aos="fade-up" id="contact">
      <div className="contact-section">

        <div className="contact-info">
          <h2 className="title">{t("contact.title")}</h2>
          <p className="desc">{t("contact.desc")}</p>
        </div>

        <form className="contact-form" id="form" onSubmit={sendEmail} ref={form}>
          {submitted ? (
            <div className="alert alert-success" role="alert">
              {t("contact.success")}
            </div>
          ) : (
            <>
              <div className="form-input w-50">
                <input type="text" placeholder=" " required name="name" value={name} onChange={(e) => setName(e.target.value)} />
                <label>{t("contact.name")}</label>
              </div>
              <div className="form-input w-50">
                <input type="number" placeholder=" " required name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <label>{t("contact.phone")}</label>
              </div>
              <div className="form-input w-100">
                <input type="text" placeholder=" " required name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <label>{t("contact.email")}</label>
              </div>
              <div className="form-input w-100">
                <textarea placeholder=" " required name="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                <label>{t("contact.message")}</label>
              </div>
              <div className="form-input w-100">
                <input
                  type="submit"
                  value={t("contact.send")}
                  className="main-btn"
                  disabled={name === "" || email === "" || phone === "" || message === "" || submitted}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
}
