import React from 'react'
import './Portfolio.css'

export default function Portfolio() {
    return (
        <iframe
            className="cv-page"
            src="https://drive.google.com/file/d/17FbPn73j-q79mVV5tgm7HJEJ2bokuLB7/preview"
            allow="autoplay"
            title="CV"
        ></iframe>
    )
}